import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../containers/layout'
import PropertyPage from '../components/page/ResidentialProperty/index'
import SEO from '../components/seo'

export const query = graphql`
  query($slug: String!) {
    sanityProperty(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      _rawBody
      price
      propertyType
      realEstateAgency
      realEstateAgent
      realEstateEmail
      realEstatePhone
      saleOrLease
      squareFootage
      title
      location {
        city
        state
        streetAddress
        zipcode
      }
      teaserImage {
        asset {
          url
          fluid(maxWidth: 720) {
            ...GatsbySanityImageFluid
          }
        }
      }
      mainImage {
        asset {
          fluid(maxWidth: 1900) {
            ...GatsbySanityImageFluid
          }
        }
      }
      seoSettings {
        title
        description
        openGraphImage {
          asset {
            url
          }
        }
      }
    }
    sanitySiteSettings {
      mainImageCommercialProperties {
        asset {
          fluid(maxWidth:1900) {
            ...GatsbySanityImageFluid
          }
        }
      }
      mainImageResidentialProperties {
        asset {
          fluid(maxWidth:1900) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`

const PagePage = ({data: {sanityProperty: page, sanitySiteSettings: site}}) => {
  // console.log({propertyImage}),
  const propertyImage = site.mainImageResidentialProperties

  // console.log({page})
  return (
    <Layout mainImage={page.mainImage && page.mainImage.asset ? page.mainImage : propertyImage} title={page.title}>
      {page.seoSettings && page.seoSettings.title && page.seoSettings.description &&
        (<SEO title={page.seoSettings.title} description={page.seoSettings.description} openGraphImage={page.seoSettings.openGraphImage ? page.seoSettings.openGraphImage.asset.url : null} />
        )}
      <PropertyPage
        title={page.title}
        _rawBody={page._rawBody}
        price={page.price}
        propertyType={page.propertyType}
        realEstateAgency={page.realEstateAgency}
        realEstateAgent={page.realEstateAgent}
        realEstateEmail={page.realEstateEmail}
        realEstatePhone={page.realEstatePhone}
        saleOrLease={page.saleOrLease}
        squareFootage={page.squareFootage}
        location={page.location}
        teaserImage={page.teaserImage}
      />
    </Layout>
  )
}

export default PagePage
